<template>
  <div v-if="device" class="max-w-7xl mx-auto pb-24">
    <DeviceCheckStep
      v-model="device.parameters"
      :device="device"
      :current-step="step"
      :disabled="service && service.status === 'spare_parts_sent'"
      @go-to-step="goToStep"
    />

    <DeviceInstallingStep
      :stepNumber="1"
      :currentStep="step"
      @go-to-step="goToStep"
      :name="$t('service.addIssueDescription')"
      description=""
      :completenessCondition="issueDescription !== '' && issue_images.length > 0"
      :disabled="service && service.status === 'spare_parts_sent'"
    >
      <div class="mt-4">
        <label for="issue_description" class="block text-sm font-medium text-gray-700">
          {{$t('service.issueDescription')}}
        </label>
        <div class="mt-1 relative">
          <textarea
            id="issue_description"
            name="issue_description"
            v-model="issueDescription"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
      <div class="my-4 space-y-4">
        <ImageInput :placeholder="$t('service.issueImage1')" id="issue-1" :index="0" :watermark-text="device.id" @file-change="handleIssueImage"/>
        <ImageInput :placeholder="$t('service.issueImage2')" id="issue-2" :index="1" :watermark-text="device.id" @file-change="handleIssueImage"/>
      </div>

    </DeviceInstallingStep>
    <DeviceInstallingStep
      :stepNumber="2"
      :currentStep="step"
      @go-to-step="goToStep"
      :name="$t('service.requestForSpareParts')"
      description=""
      :completenessCondition="sparePartsRequired === false || sparePartsDescription !== ''"
      :disabled="service && service.status === 'spare_parts_sent'"
    >
      <div class="my-4 space-y-4">
        <YesNoSelect
          v-model="sparePartsRequired"
          :title="$t('service.sparePartsQuestion')"
        />
        <div v-if="sparePartsRequired">
          <label for="spare_parts_description" class="block text-sm font-medium text-gray-700">
            {{$t("service.sparePartsDescription")}}
          </label>
          <div class="mt-1 relative">
            <textarea
              id="spare_parts_description"
              name="spare_parts_description"
              v-model="sparePartsDescription"
              class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
            />
          </div>
        </div>
      </div>
    </DeviceInstallingStep>

    <DeviceInstallingStep
      :stepNumber="3"
      :currentStep="step"
      @go-to-step="goToStep"
      :name="$t('service.confirmIssuerepair')"
      description=""
      :completenessCondition="issueFixed && fixed_images.length > 0"
    >
      <div class="my-4 space-y-4">
        <YesNoSelect
          v-model="issueFixed"
          :title="$t('service.isItrepaired')"
        />
        <ImageInput :placeholder="$t('service.fixedImage1')" id="fixed-1" :index="0" :watermark-text="device.id" @file-change="handleFixedImage"/>
        <ImageInput :placeholder="$t('service.fixedImage2')" id="fixed-2" :index="1" :watermark-text="device.id" @file-change="handleFixedImage"/>
      </div>

    </DeviceInstallingStep>

    <AddNotesStep
      v-model="notes"
      :stepIndex="4"
      :currentStep="step"
      @go-to-step="goToStep"
    />

    <DeviceInstallingStep
      :stepNumber="5"
      :currentStep="step"
      @go-to-step="goToStep"
      :name="$t('template.completeFormalities')"
      :completenessCondition="signatureImage !== null"
    >
      <div class="mt-4 mb-8">
        <SignaturePad 
          ref="signaturePad"
          v-model="signatureImage" 
          :placeholder="$t('template.customerSignature')" 
          />
  
      </div>
    </DeviceInstallingStep>

    

    <StepSwitch
      :step="step"
      :steps="5"
      @click-next="nextStep"
      @click-prev="prevStep"
    />
    <Modal
      ref="modal"
      :title="$t('service.requestForSpareParts')"
      :text="`${this.$t('service.sparePartsRequestInfo1')} ${orgName} ${this.$t('service.sparePartsRequestInfo2')}`"
      :cancel-button-label="$t('cancel')"
    >
      <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="sendSparePartsRequest">
        {{$t("submit")}}
      </button>
    </Modal>
  </div>
</template>

<script>
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
import YesNoSelect from '@/components/inputs/YesNoSelect.vue'
import ImageInput from '@/components/inputs/ImageInput.vue'
import InputWithError from '@/components/inputs/InputWithError.vue'
import StepSwitch from '@/components/deviceAction/StepSwitch.vue'
import DeviceInstallingStep from '@/components/deviceAction/DeviceInstallingStep.vue'
import DeviceCheckStep from '@/components/deviceAction/DeviceCheckStep.vue'
import AddNotesStep from '@/components/deviceAction/AddNotesStep.vue'
import SignaturePad from '@/components/inputs/SignaturePad.vue'
import generateServicePdf from '@/assets/js/generateServicePdf.js'
import Modal from '@/components/layout/Modal.vue';
import { mapState } from 'vuex'
import { getDeviceSerialNumber, createFiles } from '@/assets/js/helpers.js'
import { api } from '@/assets/js/api-client.js'

export default {
  name: "SingleService",
  components: {
    StepSwitch,
    ImageInput,
    DeviceInstallingStep,
    DeviceCheckStep,
    AddNotesStep,
    YesNoSelect,
    InputWithError,
    SignaturePad,
    PencilSquareIcon,
    Modal
  },
  data () {
    return {
      step: 0,
      issueDescription: '',
      sparePartsDescription: '',
      sparePartsRequired: null,
      issue_images: [],
      issueFixed: null,
      fixed_images: [],
      notes: {
        installer: '',
        client: ''
      },
      signatureImage: null,
      pdfFile: null,
      db: null,
      cachedRequestId: null,
      service: null
    }
  },
  computed: {
    ...mapState(['user', 'device', 'installations', 'services', 'currentInstallation']),
    orgName () {
      return process.env.VUE_APP_ORG_NAME
    }
  },
  async created () {
    let device_id
    let serviceDevice
    if (this.$route.params.id !== "new") {
      this.$store.dispatch('setLoading', true)
      try {
        const result = await api.get(`/services/${this.$route.params.id}`)
        this.service = result.data
      } catch (error) {
        console.error(error)
      }
      this.$store.dispatch('setLoading', false)
    }
    if (this.service) {
      serviceDevice = this.service.customDevice
      this.$store.dispatch('saveDevice', serviceDevice)
      this.step = 2
      this.issueDescription = this.service.issueDescription
      this.issue_images = [this.service.issue1Image, this.service.issue2Image]
      this.sparePartsRequired = true
      this.sparePartsDescription = this.service.sparePartsDescription
    } else {
      device_id = this.$route.params.deviceId
      this.$store.dispatch('setLoading', true)
      await this.$store.dispatch('getDevice', {id: device_id})
      this.$store.dispatch('setLoading', false)
    }
    console.log(this.device)
    const currentInstallation = this.device.installation
    this.$store.dispatch('saveCurrentInstallation', currentInstallation)
  },
  methods: {
    nextStep () {
      if (this.$route.params.id === 'new' && this.sparePartsRequired && this.step === 2){
        this.$refs.modal.show()
      } else if (this.step <= 4) {
        this.goToStep(this.step + 1)
      } else if (this.signatureImage !== null) {
        generateServicePdf(
          this.user,
          this.service,
          this.currentInstallation,
          this.device,
          this.issueDescription,
          this.sparePartsRequired,
          this.sparePartsDescription,
          this.notes.client,
          this.notes.installer,
          this.signatureImage,
          false,
          this.currentInstallation.language
        ).then(result => {
          this.pdfFile = result
          this.sendServicePdf()
        })

      }
      else{
        this.$notify({
          title: this.$t("template.warning"),
          text: this.$t("public.infos.createInstallationCard"),
          type: 'warning'
        })
      }
    },
    prevStep () {
      if (this.step === 0) {
        if (confirm(this.$t("public.infos.stopTheService"))) {
          this.$router.push({path: '/services'})
        }
      }
      else {
        this.step--
      }
    },
    goToStep (stepNumber) {
      console.log(stepNumber)
      switch (stepNumber) {
        case 0:
          this.step = 0;
          break;
        case 1:
          this.step = 1;
          break;
        case 2:
          if (this.issueDescription !== '' && this.issue_images.length > 0) {
            this.step = 2;
            break;
          }
          // falls through
        case 3:
          if (this.sparePartsRequired === false || (this.sparePartsDescription !== '' && this.$route.params.id !== 'new')) {
            this.step = 3;
            break;
          }
          // falls through
        case 4:
          if (this.issueFixed && this.fixed_images.length > 0) {
            this.step = 4;
            break;
          }
          // falls through
        case 5:
          if (this.issueFixed && this.fixed_images.length > 0) {
            this.step = 5;
            break;
          }
          // falls through
        default:
          if (this.step === 2 && this.sparePartsRequired && this.sparePartsDescription !== ''){
            this.$notify({
              title: this.$t("template.warning"),
              text: this.$t("public.infos.submitSparePartsRequest"),
              type: 'warning'
            })
          } else {
            this.$notify({
              title: this.$t("template.warning"),
              text: this.$t("public.infos.checkStepText"),
              type: 'warning'
            })
          }
      }
    },
    handleIssueImage (e) {
      this.issue_images[e.id] = e.image
    },
    handleFixedImage (e) {
      this.fixed_images[e.id] = e.image
    },
    sendSparePartsRequest () {
      this.$refs.modal.close()
      //this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

      const action = 'insert'
      const startDate = new Date()

      const data = {
        status: 'require_spare_parts',
        issueDescription: this.issueDescription,
        sparePartsDescription: this.sparePartsDescription,
        serviceStartDate: startDate.toLocaleString('sv').slice(0,10),
        organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`,
        customDevice: `/custom_devices/${this.device.id}`
      }

      const fileDefs = this.issue_images.map((image, index) => {
        return {label: `issue-${index+1}`, type: 'image/jpeg', base64: image.split(',')[1]}
      })

      createFiles(fileDefs, this.device.id, data, startDate.getTime())

      let request = {
        status: 0,
        endpoint: `/services`,
        type: 'service',
        state: 'online',
        incognito: null,
        action: action,
        files: fileDefs,
        data: data,
        startDate: startDate.getTime(),
        installerId: this.user.id
      }

      this.$store.dispatch('addRequest', request)
      this.$router.push({path: '/'})
    },
    sendServicePdf () {
      this.$refs.modal.close()
      // this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

      const action = this.service ? 'update' :'insert'
      const startDate = this.service ? new Date(this.service.serviceStartDate) : new Date()
      const endDate = new Date()

      const data = {
        status: 'done',
        issueDescription: this.issueDescription,
        sparePartsDescription: this.sparePartsDescription,
        serviceStartDate: startDate.toLocaleString('sv').slice(0,10),
        serviceEndDate: endDate.toLocaleString('sv').slice(0,10),
        organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`,
        customDevice: `/custom_devices/${this.device.id}`
      }

      if (this.service) data["id"] = this.service.id

      console.log(data)

      const issueImages = this.service ? [] : this.issue_images.map((image, index) => {
        return {label: `issue-${index+1}`, type: 'image/jpeg', base64: image.split(',')[1]}
      })
      const fixedImages = this.fixed_images.map((image, index) => {
        return {label: `fixed-${index+1}`, type: 'image/jpeg', base64: image.split(',')[1]}
      })
      const fileDefs = [
        {label: 'service-card', type: 'application/pdf', base64: this.pdfFile},
        ...issueImages,
        ...fixedImages
      ]

      createFiles(fileDefs, this.device.id, data, startDate.getTime())

      const emails = [ this.currentInstallation.installer.email ]
      if (this.currentInstallation.receivers) this.currentInstallation.receivers.forEach(receiver => {emails.push(receiver.email)})
      if (this.currentInstallation.customerEmail) emails.push(this.currentInstallation.customerEmail)

      const emailData = {
        organisation: process.env.VUE_APP_ORG_ID,
        device_id: getDeviceSerialNumber(this.device),
        service_card: data.serviceCard,
        execution_date: endDate.toLocaleDateString('pl-PL'),
        email: emails,
        language: this.currentInstallation.language
      }

      let request = {
        status: 0,
        endpoint: `/services`,
        type: 'service',
        state: 'online',
        incognito: null,
        action: action,
        files: fileDefs,
        data: data,
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
        installerId: this.user.id,
        emailRequest: {
          type: 'email',
          data: emailData,
          action: 'service_card',
          installerId: this.user.id
        }
      }

      this.$store.dispatch('addRequest', request)
      this.$router.push({path: '/'})
    }
  },
  watch: {
    step () {
      this.$router.push({hash: `#step${this.step}`})
    },
    notes () {
      console.log(this.notes)
    }
  }
};
</script>
