import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logos from './logosB64.js'
import i18n from '@/i18n.js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function generateServicePdf (
  user,
  service,
  currentInstallation,
  device,
  issueDescription,
  sparePartsRequired,
  sparePartsDescription,
  clientNotes,
  installerNotes,
  signatureImage,
  openPDF = true,
  lang
) {

  const t = i18n.global.t

  const issueDescriptionPart = [
    {text: t('service.faultDescription', lang), bold: true, margin: [0, 10, 0, 2]},
    {text: issueDescription}
  ]

  const sparePartsDescriptionPart = sparePartsRequired ? [
    {text: t('service.requiredPartsDescription', lang), bold: true, margin: [0, 10, 0, 2]},
    {text: sparePartsDescription}
  ] : {}

  const pdfNotesPart = {
    columns: [
      [
        {text: t('template.customerNotes', lang), bold: true, margin: [0, 30, 0, 2]},
        {text: clientNotes ? clientNotes : t('pdf.technical.noneNotes', lang)}
      ],
      [
        {text: t('template.installerNotes', lang), bold: true, margin: [0, 30, 0, 2]},
        {text: installerNotes ? installerNotes : t('pdf.technical.noneNotes', lang)}
      ]
    ]
  }

  let deviceData
  if (device.parameters) {
    deviceData = device.schemaVersion.parametersSchema.map(field => {
      return {text: [{text: `${field.labels?.[lang] ? field.labels[lang] : field.label}: `, bold: true}, device.parameters[field.name]], margin: [0, 0, 0, 2]}
    }).filter(value => { return value.text[1] })
    deviceData.unshift({text: [{text: `${t('pdf.technical.deviceType', lang)}: `, bold: true}, device.deviceTemplate.nameTranslations?.[lang] ? device.deviceTemplate.nameTranslations[lang] : device.deviceTemplate.name], margin: [0, 0, 0, 2]})
  } else {
    deviceData = [
      {text: [{text: 'Numer seryjny: ', bold: true}, device.id], margin: [0, 0, 0, 2]},
      {text: [{text: 'Model szafy: ', bold: true}, device.model], margin: [0, 0, 0, 2]},
      {text: [{text: 'Model pompy ciepła: ', bold: true}, device.heatpump], margin: [0, 0, 0, 2]},
      {text: [{text: 'Moc: ', bold: true}, device.power], margin: [0, 0, 0, 2]},
      {text: [{text: 'Wersja: ', bold: true}, device.version], margin: [0, 0, 0, 2]},
      {text: [{text: 'Bypass: ', bold: true}, device.bypass], margin: [0, 0, 0, 2]}
    ]
  }

  const installationDate = new Date(currentInstallation.installationDate).toLocaleDateString('pl-PL')
  const startDate = service ? new Date(service.serviceStartDate).toLocaleDateString('pl-PL') : new Date().toLocaleDateString('pl-PL')
  const endDate = new Date().toLocaleDateString('pl-PL')

  const docDefinition = {
    content: [
      {
        image: logos[process.env.VUE_APP_ORG_ID],
        width: 200,
        alignment: 'center'
      },
      {
        text: t('service.serviceCard', lang),
        alignment: 'center',
        margin: [0, 20, 0, 5],
        style: 'header'
      },
      {
        text: `${t('service.started', lang)}: ${startDate}, ${t('service.finished', lang)}: ${endDate}`,
        alignment: 'center',
        margin: [0, 20, 0, 20]
      },
      {
        text: t('pdf.technical.deviceData', lang),
        alignment: 'left',
        margin: [0, 0, 0, 5],
        style: 'subheader'
      },
      deviceData,
      {text: [{text: `${t('installation.installationDate', lang)}: `, bold: true}, installationDate], margin: [0, 0, 0, 2]},
      {
        columns: [
          [
            {
              text: t('pdf.technical.investorData', lang),
              alignment: 'left',
              margin: [0, 30, 0, 5],
              style: 'subheader'
            },
            {text: [{text: `${t('installation.customerName', lang)}: `, bold: true}, currentInstallation.customerName], margin: [0, 0, 0, 2]},
            {text: [{text: `${t('installation.address', lang)}: `, bold: true}, currentInstallation.customerAddress], margin: [0, 0, 0, 2]},
            {text: [{text: `${t('installation.city', lang)}: `, bold: true}, currentInstallation.customerCity], margin: [0, 0, 0, 2]},
            {text: [{text: `${t('installation.zipCode', lang)}: `, bold: true}, currentInstallation.customerZipcode], margin: [0, 0, 0, 2]}
          ],
          [
            {
              text: t('pdf.technical.servicemanData', lang),
              alignment: 'left',
              margin: [0, 30, 0, 5],
              style: 'subheader'
            },
            {text: [{text: `${t('installation.customerName', lang)}: `, bold: true}, user.name], margin: [0, 0, 0, 2]},
            {text: [{text: `${t('installation.company', lang)}: `, bold: true}, user.company], margin: [0, 0, 0, 2]},
            {text: [{text: `${t('users.tin', lang)}: `, bold: true}, user.tin], margin: [0, 0, 0, 2]}
          ],
        ]
      },
      {
        text: t('pdf.technical.deviceService', lang),
        alignment: 'left',
        margin: [0, 30, 0, 5],
        style: 'subheader'
      },
      issueDescriptionPart,
      {text: [`${t('pdf.technical.sparePartsWereRequired', lang)}: `, {text: sparePartsRequired ? t('yes', lang) : t('no', lang), bold: true}], margin: [0, 20, 0, 0]},
      sparePartsDescriptionPart,
      pdfNotesPart,
      {
        image: signatureImage,
        width: 200,
        alignment: 'right'
      },
      {
        columns: [
          {},
          [
            {
              text: t('pdf.technical.investorSignature', lang),
              style: 'small',
              alignment: 'center'
            },
            {
              text: endDate,
              style: 'small',
              alignment: 'center'
            },
          ]
        ]
      }
    ],
    defaultStyle: {
      fontSize: 10,
    },
    styles: {
       header: {
         fontSize: 16,
         bold: true
       },
       subheader: {
         fontSize: 14,
         bold: true
       },
       quote: {
         italics: true
       },
       small: {
         fontSize: 8
       }
    }
  }

  const pdfDocGenerator = pdfMake.createPdf(docDefinition)
  if (openPDF) pdfDocGenerator.open();
  return new Promise( resolve => {
    pdfDocGenerator.getBase64((file) => {
      resolve(file)
    });
  });

}

export default generateServicePdf;
